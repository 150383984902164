import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDateForInput } from "../util/formatDateForInput";
import { Media } from "../pages/ArticlesPage/Article";
import { arrayMove } from "../util/arrayMove";
import { AddressSearchInput } from "./AddressSearchInput";

type EditModalProps<T> = {
  open: boolean;
  onClose: () => void;
  title: string;
  data: T;
  columns: {
    label: string;
    key: keyof T;
    type?:
      | "array"
      | "date"
      | "datetime"
      | "select"
      | "switch"
      | "file"
      | "order"
      | "address"
      | "order_simple";
    custom?: React.ReactNode;
    editable?: boolean;
    options?: string[];
  }[];
  onSave: (data: T) => void;
};

function EditModal<T>({
  open,
  onClose,
  title,
  data,
  columns,
  onSave,
}: EditModalProps<T>): JSX.Element {
  // Temporary state to hold the form data
  const [formData, setFormData] = React.useState<T>(data);
  const [signal, setSignal] = React.useState(0);
  useEffect(() => {
    setFormData(data);
  }, [data]);
  // Handle form field change
  const handleChange = (key: keyof T, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  // Handle save button
  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title}
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", right: "10px", top: "10px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {columns.map((column) => {
          if (column.editable === false) {
            return null;
          }

          if (column.type === "switch") {
            return (
              <FormControl
                style={{
                  marginBottom: "10px",
                }}
                fullWidth
                key={String(column.key)}
              >
                <FormControlLabel
                  control={<Switch defaultChecked={!!formData[column.key]} />}
                  label={column.label}
                  value={formData[column.key]}
                  onChange={(e, value) => {
                    handleChange(column.key, value);
                  }}
                />
              </FormControl>
            );
          }

          if (column.type === "array" && Array.isArray(formData[column.key])) {
            const arr = formData[column.key] as any[];
            return (
              <div key={String(column.key)}>
                {formData[column.key] &&
                  arr.map((item, index) => (
                    <FormControl
                      style={{
                        marginBottom: "10px",
                      }}
                      fullWidth
                      key={String(column.key) + index + signal}
                    >
                      <TextField
                        key={String(column.key) + index + "value"}
                        margin="dense"
                        label={column.label}
                        type={column.type || "text"}
                        fullWidth
                        variant="outlined"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...arr];
                          newArr[index].value = e.target.value;
                          handleChange(column.key, newArr);
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            value={!!item.is_correct}
                            defaultChecked={!!item.is_correct}
                          />
                        }
                        label={"Правильно"}
                        value={!!item.is_correct}
                        onChange={(e, value) => {
                          const newArr = [
                            ...arr.map((item) => ({
                              ...item,
                              is_correct: false,
                            })),
                          ];
                          newArr[index].is_correct = value;
                          handleChange(column.key, newArr);
                          setSignal(signal + 1);
                        }}
                      />
                    </FormControl>
                  ))}
                <Button
                  onClick={() =>
                    handleChange(column.key, [
                      ...arr,
                      { value: "", is_correct: false },
                    ])
                  }
                  variant="contained"
                  color="primary"
                >
                  Добавить
                </Button>
              </div>
            );
          }

          if (column.type === "select" && column.options) {
            return (
              <FormControl
                style={{ marginBottom: "10px" }}
                fullWidth
                key={String(column.key)}
              >
                <InputLabel id="demo-simple-select-label">
                  {column.label}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData[column.key]}
                  onChange={(e) => handleChange(column.key, e.target.value)}
                >
                  {column.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {/* {column.label} */}
              </FormControl>
            );
          }

          if (column.type === "file") {
            return (
              <FormControl
                style={{ marginBottom: "10px" }}
                fullWidth
                key={String(column.key)}
              >
                <TextField
                  onChange={(e) => {
                    handleChange(column.key, (e.target as any).files);
                  }}
                  type="file"
                  key={String(column.key)}
                  inputProps={{
                    multiple: true,
                  }}
                />
              </FormControl>
            );
          }

          if (column.type === "order") {
            return (
              <FormControl
                style={{ marginBottom: "10px" }}
                fullWidth
                key={String(column.key)}
              >
                <InputLabel>{column.label}</InputLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    overflowX: "auto",
                  }}
                >
                  {(formData[column.key] as Media[]).map((item, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        border: "1px solid #ccc",
                      }}
                    >
                      <img
                        alt={`${index}`}
                        src={item.src ? item.src : ""}
                        style={{ width: "100px" }}
                      />
                      <div style={{ display: "flex" }}>
                        {index > 0 && (
                          <IconButton
                            onClick={() =>
                              handleChange(
                                column.key,
                                arrayMove(
                                  [...(formData[column.key] as Media[])],
                                  index,
                                  index - 1
                                )
                              )
                            }
                          >
                            <ArrowBackIcon />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() =>
                            handleChange(column.key, [
                              ...(formData[column.key] as Media[]).filter(
                                (_item, id) => id !== index
                              ),
                            ])
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                        {index <
                          (formData[column.key] as Media[]).length - 1 && (
                          <IconButton
                            onClick={() =>
                              handleChange(
                                column.key,
                                arrayMove(
                                  [...(formData[column.key] as Media[])],
                                  index,
                                  index + 1
                                )
                              )
                            }
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </FormControl>
            );
          }
          if (column.type === "order_simple") {
            return (
              <FormControl
                style={{ marginBottom: "10px" }}
                fullWidth
                key={String(column.key)}
              >
                <InputLabel>{column.label}</InputLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    overflowX: "auto",
                  }}
                >
                  {(formData[column.key] as string[]).map((item, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        border: "1px solid #ccc",
                      }}
                    >
                      <img
                        alt={`${index}`}
                        src={item ?? ""}
                        style={{ width: "100px" }}
                      />
                      <div style={{ display: "flex" }}>
                        {index > 0 && (
                          <IconButton
                            onClick={() =>
                              handleChange(
                                column.key,
                                arrayMove(
                                  [...(formData[column.key] as string[])],
                                  index,
                                  index - 1
                                )
                              )
                            }
                          >
                            <ArrowBackIcon />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() =>
                            handleChange(column.key, [
                              ...(formData[column.key] as string[]).filter(
                                (_item, id) => id !== index
                              ),
                            ])
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                        {index <
                          (formData[column.key] as string[]).length - 1 && (
                          <IconButton
                            onClick={() =>
                              handleChange(
                                column.key,
                                arrayMove(
                                  [...(formData[column.key] as string[])],
                                  index,
                                  index + 1
                                )
                              )
                            }
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </FormControl>
            );
          }

          if (column.type === "datetime") {
            return (
              <FormControl
                style={{ marginBottom: "10px" }}
                fullWidth
                key={String(column.key)}
              >
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={column.label}
                  value={formData[column.key]}
                  onChange={(e) => {
                    handleChange(column.key, e.target.value);
                  }}
                  type="datetime-local"
                  key={String(column.key)}
                />
              </FormControl>
            );
          }

          if (column.type === "address") {
            return (
              <div style={{ marginBottom: "10px" }}>
                <AddressSearchInput
                  defaultValue={formData[column.key] as string}
                  onSelect={(value) => {
                    handleChange(column.key, value);
                  }}
                />
              </div>
            );
          }

          return (
            <TextField
              key={String(column.key)}
              margin="dense"
              label={column.label}
              type={column.type || "text"}
              fullWidth
              variant="outlined"
              value={
                column.type === "date"
                  ? formatDateForInput(formData[column.key] as Date)
                  : (formData[column.key] as unknown as string)
              } // Type casting might be required depending on T
              onChange={(e) => handleChange(column.key, e.target.value)}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary" variant="contained">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditModal;
